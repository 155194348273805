import React from 'react';
import { useParams } from 'react-router-dom';
import { fetchProductById } from '../Services/GraphqlRequests';
import { GrNext, GrPrevious } from "react-icons/gr";
import parse from 'html-react-parser';

class ProductDetailsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            product: null,
            selectedOptions: {},
            selectedImage: null,
            loading: true,
            error: null,
            selectedImageIndex: 0,
        };
    }

    async componentDidMount() {
        const { id } = this.props; // Get id from props
        try {
            const productData = await fetchProductById(id);

            if (productData && productData.attributes) {
                const mergedAttributes = {};
                productData.attributes.forEach(attribute => {
                    if (!mergedAttributes[attribute.name]) {
                        mergedAttributes[attribute.name] = [attribute.value];
                    } else {
                        mergedAttributes[attribute.name].push(attribute.value);
                    }
                });
                productData.attributes = Object.entries(mergedAttributes).map(([name, values]) => ({
                    name,
                    values
                }));
            }

            this.setState({
                product: productData,
                selectedImage: productData.images[0], // Set the first image as selected by default
                loading: false,
            });
        } catch (error) {
            console.error('Error fetching product:', error);
            this.setState({
                error: 'Failed to fetch product details',
                loading: false,
            });
        }
    }

    handleOptionChange = (optionName, optionValue) => {
        const updatedOptions = { ...this.state.selectedOptions, [optionName]: optionValue };

        Object.keys(this.state.selectedOptions).forEach(name => {
            if (name === optionName && this.state.selectedOptions[name] !== optionValue) {
                delete updatedOptions[name];
            }
        });

        this.setState({ selectedOptions: updatedOptions });
    };

    handleAddToCart = () => {
        const { product, selectedOptions, selectedImage } = this.state;
        if (product) {
            const selectedOptionsArray = Object.entries(selectedOptions).map(([name, value]) => ({ name, value }));
            this.props.addToCart({ ...product, selectedOptions: selectedOptionsArray, image: selectedImage });
        }
    };

    handleImageClick = (image, index) => {
        this.setState({ selectedImage: image, selectedImageIndex: index });
    };

    handleNextImage = () => {
        const { selectedImageIndex, product } = this.state;
        const nextIndex = (selectedImageIndex + 1) % product.images.length;
        this.setState({ selectedImage: product.images[nextIndex], selectedImageIndex: nextIndex });
    };

    handlePreviousImage = () => {
        const { selectedImageIndex, product } = this.state;
        const prevIndex = (selectedImageIndex - 1 + product.images.length) % product.images.length;
        this.setState({ selectedImage: product.images[prevIndex], selectedImageIndex: prevIndex });
    };

    render() {
        const { product, loading, error, selectedImage, selectedOptions } = this.state;

        return (
            <div className="container mt-5">
                {loading ? (
                    <p>Loading...</p>
                ) : error ? (
                    <p>Error: {error}</p>
                ) : product ? (
                    <div className="row" id="productDetailsPage">
                        <div className="col-md-6" id="product-image">
                            <div className="main-image" data-testid='product-gallery'>
                                <img src={selectedImage.url} alt={selectedImage.alt} />
                                <div className="prev-next-buttons">
                                    <GrPrevious onClick={this.handlePreviousImage} className='prevNex' />
                                    <GrNext onClick={this.handleNextImage} className='prevNex' />
                                </div>
                            </div>
                            <div className="thumbnail-images">
                                {product.images.map((image, index) => (
                                    <img
                                        key={index}
                                        src={image.url}
                                        alt={image.alt}
                                        className={selectedImage === image ? 'selected' : ''}
                                        onClick={() => this.handleImageClick(image, index)}
                                    />
                                ))}
                            </div>
                        </div>

                        <div className="col-md-4" id="product-details">
                            <h2>{product.name}</h2>
                            {product.attributes &&
                                product.attributes.map((attribute, index) => (
                                    <div key={index} data-testid={`product-attribute-${attribute.name.toLowerCase().replace(/\s+/g, '-').replace(/_/g, '-')}`}>
                                        {attribute.name.toLowerCase() !== 'color' ? (
                                            <div>
                                                <h6>{attribute.name}:</h6>
                                                <div className="options" style={{ marginBottom: '15px' }}>
                                                    {attribute.values && attribute.values.map((value, idx) => (
                                                        <button
                                                            key={`${attribute.name}-${idx}`}
                                                            className={`attribute-button ${selectedOptions[attribute.name] === value ? 'selected' : ''}`}
                                                            onClick={() => this.handleOptionChange(attribute.name, value)}
                                                        >
                                                            {value}
                                                        </button>
                                                    ))}
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="options" style={{ marginBottom: '15px' }}>
                                                <h6>Colors :</h6>
                                                {attribute.values && attribute.values.map((value, idx) => (
                                                    <button
                                                        key={`${attribute.name}-${idx}`}
                                                        className={`attribute-button ${selectedOptions[attribute.name] === value ? 'selected' : ''}`}
                                                        style={{ backgroundColor: value, color: 'transparent' }}
                                                        onClick={() => this.handleOptionChange(attribute.name, value)}
                                                        data-testid={`product-attribute-color-${value.replace('#', '')}`} // Use color code without # or custom name
                                                    >
                                                        {value}
                                                    </button>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                ))}

                            <h4>Price: <br /><br />${product.price.toFixed(2)}</h4>

                            {!product.inStock && (
                                <p className="text-danger">Out of Stock</p>
                            )}
                            {product.inStock && (
                                <button
                                    className="btn btn-success" id="buttonAddToCart"
                                    onClick={this.handleAddToCart}
                                    disabled={!Object.keys(selectedOptions).length}
                                    data-testid='add-to-cart'
                                >
                                    Add to Cart
                                </button>
                            )}

                            <div id="description" data-testid='product-description'>{parse(product.description)}</div>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}

// Wrapper component to extract params
const ProductDetailsPageWrapper = (props) => {
    const { id } = useParams(); // Get id from URL parameters
    return <ProductDetailsPage {...props} id={id} />; // Pass id as prop
};

export default ProductDetailsPageWrapper;
