import React, { Component } from 'react';
import { Link, useParams } from 'react-router-dom';
import { fetchProducts } from '../Services/GraphqlRequests';
import { LuShoppingCart } from 'react-icons/lu';

class ProductListingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            categoryName: 'All', // Default to 'All'
            loading: true, // Added loading state
            error: null,   // Added error state
        };
    }

    async componentDidMount() {
        await this.fetchProductData();
    }

    async componentDidUpdate(prevProps) {
        const { category } = this.props;
        if (category !== prevProps.category) {
            await this.fetchProductData();
        }
    }

    fetchProductData = async () => {
        try {
            this.setState({ loading: true, error: null }); // Reset loading and error states
            const productsData = await fetchProducts();
            const { category } = this.props;

            this.setState({
                products: productsData,
                categoryName: category || 'All',
            });
        } catch (error) {
            console.error('Error fetching products:', error);
            this.setState({ error: 'Failed to load products' });
        } finally {
            this.setState({ loading: false });
        }
    };

    getFilteredProducts = () => {
        const { categoryName, products } = this.state;

        if (!categoryName || categoryName.toLowerCase() === 'all') {
            return products;
        }

        return products.filter(product =>
            product.category && product.category.name.toLowerCase() === categoryName.toLowerCase()
        );
    };

    handleQuickShop = (product) => {
        if (product.attributes && product.attributes.length > 0) {
            const defaultSelectedOptions = product.attributes.map(attribute => ({
                name: attribute.name,
                value: attribute.values && attribute.values.length > 0 ? attribute.values[0] : ''
            }));
            this.props.addToCart({ ...product, selectedOptions: defaultSelectedOptions, fromQuickShop: true });
        } else {
            console.error('Product attributes are missing or empty:', product);
        }
    };

    toKebabCase = (str) => {
        return str
            .toLowerCase()
            .replace(/\s+/g, '-')
            .trim();
    };

    render() {
        const { loading, error, categoryName } = this.state;
        const filteredProducts = this.getFilteredProducts();

        if (loading) {
            return <p>Loading products...</p>; // Display a loading message while fetching
        }

        if (error) {
            return <p>{error}</p>; // Display error message if there's an issue fetching
        }

        return (
            <div className="container mt-5">
                <h2 className="mb-4" id="TitlListing">{categoryName} Products</h2>
                <div className="row">
                    {filteredProducts.map(product => (
                        <div key={product.id} className="col-12 col-sm-6 col-md-4 mb-0">
                            <div className="card" data-testid={`product-${this.toKebabCase(product.name)}`}>
                                <Link to={`/product/${product.id}`}>
                                    <div className="image-container">
                                        {product.images && product.images.length > 0 ? (
                                            <img
                                                src={product.images[0].url}
                                                alt={product.name}
                                                className={product.inStock ? '' : 'out-of-stock'}
                                            />
                                        ) : (
                                            <div className="card-placeholder">Image Placeholder</div>
                                        )}
                                    </div>
                                    {!product.inStock && (
                                        <div className="out-of-stock-overlay">
                                            <p>OUT OF STOCK</p>
                                            <div className="out-of-stock-style"></div>
                                        </div>
                                    )}
                                </Link>
                                {product.inStock && (
                                    <button className="quick-shop-btn" onClick={() => this.handleQuickShop(product)}>
                                        <LuShoppingCart />
                                    </button>
                                )}
                                <div className="card-body">
                                    <h5 className="card-title">{product.name}</h5>
                                    {product.price !== undefined ? (
                                        <p className="card-text">${product.price.toFixed(2)}</p>
                                    ) : (
                                        <p className="card-text">Price Unavailable</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

// Wrapper component to extract params
const CategoryWrapper = (props) => {
    const { category } = useParams();
    return <ProductListingPage {...props} category={category} />;
};

export default CategoryWrapper;
