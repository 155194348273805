import React, { Component } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// components
import ProductListingPage from './Components/ProductListingPage';
import ProductDetailPage from './Components/ProductDetailPage';
import Header from './Components/Header';
import CartOverlay from './Components/CartOverlay';

// css style
import './App.css';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isCartOverlayOpen: false,
            cartItems: this.loadCartItems(),  // Load items from sessionStorage
            headerCartItems: this.loadCartItems().length,  // Get initial count from loaded items
        };
    }

    loadCartItems = () => {
        const storedItems = sessionStorage.getItem('cartItems');
        return storedItems ? JSON.parse(storedItems) : [];  // Parse JSON if items exist
    };

    saveCartItems = (items) => {
        sessionStorage.setItem('cartItems', JSON.stringify(items));  // Save items to sessionStorage
    };

    addToCart = (product) => {
        const { cartItems } = this.state;
        const isFromQuickShop = product.fromQuickShop;

        // Check if the product already exists in the cart with the same options
        const existingProductIndex = cartItems.findIndex(item =>
            JSON.stringify({
                id: item.id,
                options: (item.selectedOptions || []).map(option => `${option.name}:${option.value}`).join(',')
            }) === JSON.stringify({
                id: product.id,
                options: (product.selectedOptions || []).map(option => `${option.name}:${option.value}`).join(',')
            })
        );

        let updatedCartItems;
        if (existingProductIndex !== -1) {
            updatedCartItems = cartItems.map((item, index) => {
                if (index === existingProductIndex) {
                    return { ...item, quantity: item.quantity + 1 };
                }
                return item;
            });
        } else {
            updatedCartItems = [...cartItems, { ...product, quantity: 1, fromQuickShop: isFromQuickShop }];
        }

        this.setState({
            cartItems: updatedCartItems,
            headerCartItems: updatedCartItems.length,
        });

        this.saveCartItems(updatedCartItems);
    };

    handleToggleCartOverlay = async () => {
        await this.setState(prevState => ({
            isCartOverlayOpen: !prevState.isCartOverlayOpen
        }));
    };

    updateHeaderCartItems = (count) => {
        this.setState({ headerCartItems: count });
    };

    render() {
        const { isCartOverlayOpen, cartItems, headerCartItems } = this.state;

        return (
            <BrowserRouter>
                <>
                    <Header
                        cartItemCount={headerCartItems}
                        cartItems={cartItems}
                        setHeaderCartItems={this.updateHeaderCartItems}
                    />
                    <Routes>
                        <Route path="/" element={<ProductListingPage addToCart={this.addToCart} />} />
                        <Route path="/all" element={<ProductListingPage addToCart={this.addToCart} />} />
                        <Route path="/:category" element={<ProductListingPage addToCart={this.addToCart} />} />
                        <Route path="/product/:id" element={<ProductDetailPage addToCart={this.addToCart} handleCloseCartOverlay={this.handleToggleCartOverlay} />} />
                    </Routes>
                    {isCartOverlayOpen && (
                        <CartOverlay
                            handleCloseCartOverlay={this.handleToggleCartOverlay}
                            cartItems={cartItems}
                            updateHeaderCartItems={this.updateHeaderCartItems}
                        />)}
                </>
            </BrowserRouter>
        );
    }
}

export default App;
