import React from 'react';
import { insertOrder } from '../Services/GraphqlRequests';

class CartOverlay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            isPlacingOrder: false,
            showOrderPlacedAlert: false,
        };
    }

    componentDidMount() {
        const storedCartItems = sessionStorage.getItem('cartItems');
        if (storedCartItems) {
            this.updateProducts(JSON.parse(storedCartItems));
        } else {
            this.updateProducts([]); // Empty cart if no items are in session storage
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.cartItems !== this.props.cartItems) {
            this.updateProducts(this.props.cartItems);
            sessionStorage.setItem('cartItems', JSON.stringify(this.props.cartItems));
        }
    }

    updateProducts(cartItems) {
        const products = cartItems.map(item => {
            const isFromQuickShop = item.fromQuickShop;
            const groupedAttributes = isFromQuickShop ? this.groupAttributesByName(item.attributes || []) : {};
            return {
                ...item,
                groupedAttributes,
                isFromQuickShop,
            };
        });
        this.setState({ products });
    }

    groupAttributesByName(attributes) {
        const groupedAttributes = {};
        attributes.forEach(attribute => {
            if (!groupedAttributes[attribute.name]) {
                groupedAttributes[attribute.name] = [];
            }
            groupedAttributes[attribute.name].push(attribute.value);
        });
        return groupedAttributes;
    }

    handleDefaultSelectedOption(attributeName, values, isFromQuickShop) {
        const selectedOption = this.state.products.map(product => {
            return {
                ...product,
                selectedOptions: product.selectedOptions.map(option => {
                    if (option.name === attributeName && (isFromQuickShop || option.value === values[0])) {
                        return { ...option, value: values[0] };
                    }
                    return option;
                }),
            };
        });
        this.setState({ products: selectedOption });
    }

    generateProductKey(product) {
        const optionsString = (product.selectedOptions || []).map(option => `${option.name}:${option.value}`).sort().join(',');
        return `${product.id}-${optionsString}`;
    }

    handleAddToCart(productKey) {
        this.setState(prevState => {
            const products = prevState.products.map(product => {
                if (this.generateProductKey(product) === productKey) {
                    return { ...product, quantity: product.quantity + 1 };
                }
                return product;
            });
            return { products };
        });
    }

    handleRemoveFromCart(productKey) {
        this.setState(prevState => {
            const updatedProducts = prevState.products.map(product => {
                if (this.generateProductKey(product) === productKey) {
                    const updatedQuantity = product.quantity - 1;
                    return { ...product, quantity: Math.max(updatedQuantity, 0) };
                }
                return product;
            });

            const filteredProducts = updatedProducts.filter(product => product.quantity > 0);
            this.props.setHeaderCartItems(filteredProducts.reduce((count, product) => count + product.quantity, 0));

            sessionStorage.setItem('cartItems', JSON.stringify(filteredProducts));
            return { products: filteredProducts };
        });
    }

    handlePlaceOrder = async () => {
        const { products } = this.state;
        if (products.length === 0) return;
        this.setState({ isPlacingOrder: true });
        try {
            const productIds = products.map(product => product.id);
            const totalPrice = products.reduce((total, product) => total + (product.price * product.quantity), 0);
            await insertOrder(productIds, totalPrice);

            this.setState({ products: [], showOrderPlacedAlert: true });
            this.props.setHeaderCartItems(0);
            sessionStorage.removeItem('cartItems');

            setTimeout(() => {
                this.setState({ showOrderPlacedAlert: false });
            }, 2000);
        } catch (error) {
            console.error('Error placing order:', error);
        } finally {
            this.setState({ isPlacingOrder: false });
        }
    };

    getSizeHint(size) {
        switch (size) {
            case 'Small': return 'S';
            case 'Medium': return 'M';
            case 'Large': return 'L';
            case 'Extra Large': return 'XL';
            default: return size;
        }
    }

    render() {
        const { products, isPlacingOrder, showOrderPlacedAlert } = this.state;
        const totalPrice = products.reduce((total, product) => total + (product.price * product.quantity), 0);

        return (
            <div className="cart-overlay">
                <div className="cart-overlay-content">
                    <div className="cart-header">
                        <p><strong>My Bag.</strong> {products.length} items</p>
                    </div>
                    <div className="cart-products">
                        {products.map((product, index) => (
                            <div className="cart-product" key={`${this.generateProductKey(product)}-${index}`}>
                                <div className="product-info">
                                    <p>{product.name}</p>
                                    <h6 className='cartPrice'>${product.price ? product.price.toFixed(2) : ''}</h6>
                                    {product.isFromQuickShop ? (
                                        Object.keys(product.groupedAttributes).map((attributeName, index) => {
                                            const kebabName = attributeName.replace(/\s+/g, '-').toLowerCase();
                                            return (
                                                <div key={index} data-testid={`cart-item-attribute-${kebabName}`}>
                                                    <h6>{attributeName}:</h6>
                                                    <div className="options">
                                                        {Array.from(new Set(product.groupedAttributes[attributeName])).map((value, idx) => (
                                                            <button
                                                                key={`${attributeName}-${idx}`}
                                                                className={`attribute-button ${idx === 0 ? 'selected' : ''}`}
                                                                onClick={() => this.handleDefaultSelectedOption(attributeName, product.groupedAttributes[attributeName], true)}
                                                                data-testid={`cart-item-attribute-${kebabName}-${value}`}
                                                                disabled
                                                            >
                                                                {attributeName.toLowerCase() === 'color' ? (
                                                                    <span style={{ backgroundColor: value, width: '10px', height: '10px', display: 'inline-block' }}></span>
                                                                ) : (
                                                                    this.getSizeHint(value)
                                                                )}
                                                            </button>
                                                        ))}
                                                    </div>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        product.attributes && product.attributes.map((attribute, index) => (
                                            <div key={index} data-testid={`cart-item-attribute-${attribute.name.replace(/\s+/g, '-').toLowerCase()}`}>
                                                <h6>{attribute.name}:</h6>
                                                <div className="options">
                                                    {attribute.values && attribute.values.map((value, idx) => (
                                                        <button
                                                            key={`${attribute.name}-${idx}`}
                                                            className={`attribute-button ${product.selectedOptions.find(option => option.name === attribute.name && option.value === value) ? 'selected' : ''}`}
                                                            onClick={() => this.props.handleOptionChange(attribute.name, value)}
                                                            data-testid={`cart-item-attribute-${attribute.name.replace(/\s+/g, '-').toLowerCase()}-${value}`}
                                                            disabled
                                                        >
                                                            {attribute.name.toLowerCase() === 'color' ? (
                                                                <span style={{ backgroundColor: value, width: '25px', height: '25px', display: 'inline-block' }}></span>
                                                            ) : (
                                                                this.getSizeHint(value)
                                                            )}
                                                        </button>
                                                    ))}
                                                </div>
                                            </div>
                                        ))
                                    )}
                                </div>
                                <div className="quantity-controls">
                                    <button
                                        data-testid='cart-item-amount-decrease'
                                        onClick={() => this.handleRemoveFromCart(this.generateProductKey(product))}
                                    >
                                        -
                                    </button>
                                    <span data-testid='cart-item-amount'>{product.quantity}</span>
                                    <button
                                        data-testid='cart-item-amount-increase'
                                        onClick={() => this.handleAddToCart(this.generateProductKey(product))}
                                    >
                                        +
                                    </button>
                                </div>
                                <div className="CartImage">
                                    {product.images && product.images.length > 0 && product.images[0] && (
                                        <img className="cart-product-image" src={product.images[0].url} alt={product.name} />
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="TotalPrice">
                    <div className="cart-total" data-testid='cart-total'>
                        <p>Total: </p>
                        <p>${totalPrice.toFixed(2)}</p>
                    </div>
                    <button
                        className="place-order-button"
                        disabled={products.length < 1 || isPlacingOrder}
                        onClick={this.handlePlaceOrder}
                    >
                        {isPlacingOrder ? 'Placing Order...' : 'Place Order'}
                    </button>
                    {showOrderPlacedAlert && (
                        <div className="order-alert">
                            <p>Order placed successfully!</p>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default CartOverlay;
