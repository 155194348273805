import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { fetchCategories } from '../Services/GraphqlRequests';
import { LuShoppingCart } from 'react-icons/lu';
import CartOverlay from './CartOverlay';
import { useNavigate, useLocation } from 'react-router-dom';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            isCartOpen: false,
            isCategoryOpen: false,
            loading: true, // Add loading state
        };
    }

    async componentDidMount() {
        await this.fetchData();
        this.setState({ loading: false }); // Ensure categories are loaded
    }

    fetchData = async () => {
        try {
            const categoriesData = await fetchCategories();
            this.setState({ categories: categoriesData });
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    toggleCartOverlay = async () => {
        await this.setState((prevState) => ({ isCartOpen: !prevState.isCartOpen }));
    };

    toggleCategoryDropdown = () => {
        this.setState((prevState) => ({ isCategoryOpen: !prevState.isCategoryOpen }));
    };

    goToHomePage = () => {
        this.props.navigate('/');
    };

    render() {
        const { categories, isCartOpen, isCategoryOpen, loading } = this.state;
        const { cartItemCount, cartItems, setHeaderCartItems, location } = this.props;

        return (
            <nav className="navbar navbar-expand-lg" id="navbarID">
                <div className="container">
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        onClick={this.toggleCategoryDropdown}
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    {/* Render categories only after loading */}
                    {!loading && (
                        <div className={`collapse navbar-collapse ${isCategoryOpen ? 'show' : ''}`} id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                {categories.map((category) => (
                                    <li className="nav-item" id="category-name-color" key={category.id}>
                                        <Link
                                            className={`nav-link ${location.pathname === `/${category.name.toLowerCase()}` ? 'active' : ''}`}
                                            to={category.name.toLowerCase() === 'all' ? '/' : `/${category.name.toLowerCase()}`}
                                            data-testid={location.pathname === `/${category.name.toLowerCase()}` ? 'active-category-link' : 'category-link'}
                                        >
                                            {category.name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    <a className="navbar-brand" id="center-logo" href="/" onClick={this.goToHomePage}>
                        <img src="/logoscandiweb.png" alt="logo" />
                    </a>
                    {/* Cart Icon */}
                    <div className="icon">
                        <button className="nav-link CartIcon" onClick={this.toggleCartOverlay} data-testid="cart-btn">
                            <LuShoppingCart />
                            {cartItemCount > 0 && <span className="cart-item-count">{cartItemCount}</span>}
                        </button>
                    </div>
                </div>

                {/* Conditionally render the cart overlay */}
                {isCartOpen && (
                    <CartOverlay
                        handleCloseCartOverlay={this.toggleCartOverlay}
                        cartItems={cartItems}
                        setHeaderCartItems={setHeaderCartItems}
                    />
                )}
                {isCartOpen && <div className="overlay-backdrop" onClick={this.toggleCartOverlay}></div>}
            </nav>
        );
    }
}

// Wrapper to pass hooks as props to the class component
const HeaderWithRouter = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    return <Header {...props} navigate={navigate} location={location} />;
};

export default HeaderWithRouter;
